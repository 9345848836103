"use client";

import { useEffect, useContext } from "react";
import { AuthContext, ConfigContextV2 } from "@finbackoffice/site-core";
import { NotFound } from "components/pages/404/NotFound";

const NotFoundError404 = ({ token }: { token?: string }) => {
    const { loadSiteConfig } = useContext(ConfigContextV2);
    const { setToken } = useContext(AuthContext);

    useEffect(() => {
        if (token) {
            setToken(token);
        }
        loadSiteConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <NotFound statusCode={404} />;
};

export default NotFoundError404;
