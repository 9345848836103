import { FC, cloneElement, ReactElement, PropsWithChildren, useMemo } from "react";
import { ITranslateProps, useTranslation } from "@finbackoffice/site-core";
import { TranslationScopes } from "@finbackoffice/enums";

const Translate: FC<PropsWithChildren<ITranslateProps>> = ({
    tid,
    replace,
    dangerous,
    children,
    namespace,
    text,
}) => {
    const { t } = useTranslation(namespace || TranslationScopes.Common);

    return useMemo(() => {
        if (dangerous) {
            return (
                <>
                    {cloneElement(children as ReactElement<any>, {
                        dangerouslySetInnerHTML: {
                            __html: tid ? (replace ? t(tid, replace) : t(tid)) : text,
                        },
                    })}
                </>
            );
        }

        return tid ? (replace ? t(tid, replace) : t(tid)) : null;
    }, [t, dangerous, replace, children, tid, text]);
};

export default Translate;
